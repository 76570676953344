import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const getUsers = () => {
  return api.get(url.GET_USERS)
}

//generate raport for uuid
export const generateReportForUser = (data) => {
  return api.create(url.GET_REPORT,data)
}

//get raports for uuid
export const getAllReportsForUser = (data) => {
  return api.get(url.GET_REPORT,data)
}


