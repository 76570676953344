import React from 'react';
import { Switch, Route } from "react-router-dom";

import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from './AuthProtected';

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    return (
        <React.Fragment>
       
            <Switch>
                <Route path={availablePublicRoutesPaths}>

                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route
                                    path={route.path}
                                    component={route.component}
                                    key={idx}
                                    exact={true}
                                />
                            ))}
                        </Switch>

                </Route>

                <Route path={availableAuthRoutesPath}>
                    <AuthProtected>

                            <Switch>
                                {authProtectedRoutes.map((route, idx) => {
                                    return (
                                        <AccessRoute
                                            path={route.path}
                                            component={route.component}
                                            key={idx}
                                            group={route.group}
                                            exact={true}
                                        />
                                    )
                                })}
                            </Switch>

                    </AuthProtected>
                </Route>
            </Switch>

        </React.Fragment>
    );
};

export default Index;