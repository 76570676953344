module.exports = {
  google: {
    GEOCODE_API_KEY: process.env.REACT_APP_GEOCODE_API_KEY,
    CLIENT_ID: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
    SECRET: "",
  },
  facebook: {
    APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  },
  DEFAULT_AUTH: process.env.REACT_APP_DEFAULT_AUTH,
  API_URL: process.env.REACT_APP_API_URL || "http://127.0.0.1:8000",
}
