import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.API_URL,
  timeout: 30000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// default
// instance.defaults.baseURL = process.env.API_URL;
// content type
instance.defaults.headers.post["Content-Type"] = "application/json";

// instance.defaults.xsrfCookieName = 'csrftoken'
instance.defaults.xsrfHeaderName = 'X-CSRFToken'
instance.defaults.withCredentials = true
// instance.interceptors.response.use(response => {
//   const sessionCookie = Cookie.get()
//   instance.defaults.headers.common['X-CSRFToken'] = sessionCookie["XSRF-TOKEN"];
//   return response
// })
// intercepting to capture errors
instance.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // console.log(error.response);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // let message;
    // switch (error.status) {
    //   case 500:
    //     message = "Internal Server Error";
    //     break;
    //   case 401:
    //     message = "Invalid credentials";
    //     break;
    //   case 404:
    //     message = "Sorry! the data you are looking for could not be found";
    //     break;
    //   default:
    //     message = error.message || error;
    // }
    return Promise.reject(error.response ? error.response : error);
    // return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  instance.defaults.headers.common["X-CSRFToken"] = token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return instance.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key])
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = instance.get(`${url}?${queryString}`, params);
    } else {
      response = instance.get(`${url}`, params);
    }

    return response;
  }
  /**
   * post given data to url
   */
  create = (url, data) => {
    return instance.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return instance.put(url, data);
  };

  patch = (url, data) => {
    return instance.patch(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return instance.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };