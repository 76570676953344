
import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import {generateReportForUser,getAllReportsForUser} from '../../helpers/backend_helper'
import "./index.scss";

const Starter = (props) => {
  const [reportResult,setReportResult]=useState([])
  const [allReports,setAllReports]=useState([])
  const [disableButton,setDisableButton]=useState(false)

  const [err,setErr]=useState('')

  const [uuid,setUiid]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')

  useEffect(()=>{
    if(reportResult.length!==0 && uuid && password){
      if( reportResult?.detail){
      setErr(reportResult.detail)
    }}
  },[reportResult,uuid,password])

  const generateReport = async () => {
    try {
      if (uuid !== '' && email !== '' && password !== '') {
        const payload = { 'UUID': uuid, 'email': email, 'password': password };
  
        const allReports = await getAllReportsForUser(payload);
        setDisableButton(true);
        setAllReports(allReports);
  
        const reportResponse = await generateReportForUser(payload);
        setDisableButton(true);
        setReportResult(reportResponse);

      }
    } catch (e) {
 
      setErr(e);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  document.title ='Mindsteep';
    return (
      <React.Fragment>      
        <div className="page-content">
         

          <h1 className="page-title">Mindsteep Report</h1>
          <div className="inputs-wrapper">
            <label>UUID : </label>
            <input className='input' type="text" id="uuid" name="uuid" 
              onChange={(event) => setUiid(event.target.value)}
              value={uuid}/>
            <br></br>
          </div>
          <label>Password : </label>
          <div className="inputs-wrapper-password">
            
            <input className='input'  type={passwordShown ? "text" : "password"} id="password" name="password" 
              onChange={(event) => setPassword(event.target.value)}
              value={password}/>
            <br></br>
            <button className="togglePassword" type="button" onClick={togglePassword} id="password-addon"><AiFillEye size={20}/></button>
          </div>

          <div className="inputs-wrapper">
            <label>Email : </label>
            <input className='input' type="text" id="email" name="email" 
              onChange={(event) => setEmail(event.target.value)}
              value={email}/>
            <br></br>
          </div>

          <button onClick={()=>{generateReport();}} 
            className={disableButton? 'btn-disabled':'btn'} disabled={disableButton}
            type="submit" >Generate report
          </button> 

          {disableButton && reportResult?.detail!=="Check your inbox!" && err==='' &&<p>Please wait!</p>}
          {( (err!=='' ||  reportResult?.detail) && uuid) && 
          (<p style={{fontWeight:'bold'}}>{reportResult.detail}</p>)
          }
          {disableButton && (reportResult?.detail==="Check your inbox!" || reportResult?.detail==="Wrong password!") && <p>Please refresh the page and try again!</p>}
        </div>
        
      </React.Fragment>
    );
};

export default (Starter);