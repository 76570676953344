
import './App.css';
import React from 'react'
//imoprt Route
import Route from './Routes';

function App() {

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
