import React from "react";
import { Redirect } from "react-router-dom";

import RaportMindsteep from "../pages/RaportMindsteep";




const authProtectedRoutes = [
];

const publicRoutes = [
  // Authentication Page
  { path: "/dashboard", component: RaportMindsteep },
  { path: "/", component: RaportMindsteep },
];

export { authProtectedRoutes, publicRoutes };